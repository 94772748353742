import React from 'react';

import img1 from '../img/course-01.jpg';
import img2 from '../img/course-02.jpg';
import img3 from '../img/course-03.jpg';

const CardCourse = ({ course }) => {

    const { title, text, img, price, includes } = course;
    return (
        <div class="col-sm-6 col-lg-4 p-t-30">

            <div class="block-3 bo2">
                <div class="wrap-pic-b3 wrap-pic-w hov5 bo2-b">
                    <a href="course-detail.html"><img src={img} alt="IMG-COURSE" /></a>
                </div>

                <div className="wrap-text-b3 p-l-20 p-r-20 p-t-16 p-b-26 bgwhite">
                    <div className="flex-sb-m p-b-12">
                        <h4 class="m-txt8 hov-color-main trans-04 p-b-12">
                            {title}
                        </h4>                      
                        <span class="color1 fs-12">
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                        </span>
                    </div>
                    <p>Incluye:</p>
                    <ul className="mar-top-10 mar-bottom-10 mar-left-10">
                        {includes.map(c => <li><span className="fa fa-check text-green"></span> {c}</li>)}
                    </ul>
                    <span className="m-txt9">$ {parseFloat(price).toFixed(2)}</span>

                    <div className="wrap-btn-b3 flex-w p-t-13">
                        <div class="m-r-8 p-t-8">
                            <a
                                className="btn-drive m-txt1 size3 bg-main hov-color-white bo-rad-4"
                                href="https://wa.me/+50582873848"
                                target="_blank">

                                Contactar ahora 
                                <span className="fa fa-whatsapp m-l-18" aria-hidden="true">

                                </span>
                            </a>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

const Courses = () => {

    const courses = [
        {
            title: 'Curso de Moto',
            text: 'Licencia para conducir motos',
            price: 100,
            img: img1,
            includes: [
                '1 Seminario teórico',
                '15 Horas practicas',
                'Material de estudio',
                'Acompañamiento en tramite de licencia'
            ]
        },
        {
            title: 'Curso Principiante de Vehiculo',
            text: 'Licencia para conducir vehiculos livianos',
            price: 140,
            img: img2,
            includes: [
                '1 Seminario teórico',
                '10 Horas practicas',
                'Material de estudio',
                'Acompañamiento en tramite de licencia'
            ]
        },
        {
            title: 'Curso Intermedio de Vehiculo',
            text: 'Licencia para conducir vehiculos livianos',
            price: 110,
            img: img3,
            includes: [
                '1 Seminario teórico',
                '10 Horas practicas',
                'Material de estudio',
                'Acompañamiento en tramite de licencia'
            ]
        },
    ]

    return (
        <div>
            <section class="bg1 p-t-75 p-b-90">
                <div class="container">
                    <div class="p-b-10">
                        <div class="p-b-24">
                            <h3 class="m-txt4 txt-center p-b-8 respon1">
                                Nuestros cursos
                            </h3>

                            <div class="bg-main size2 bo-rad-3 m-lr-auto"></div>
                        </div>

                        <p class="w-size2 txt-center m-txt7 m-lr-auto p-l-15 p-r-15">
                            Todos nuestros cursos tienen una duración de 15 horas y pueden ser pagados hasta en 3 cuotas
                        </p>
                    </div>

                    <div class="row">

                        {courses && courses.map(course => <CardCourse course={course} ></CardCourse>)}



                    </div>

                   
                </div>
            </section>
        </div>
    );
}

export default Courses;
