import React from 'react';

const Breadcrumb = () => {
    return (
        <div class="bg1 p-t-29 p-b-29">
		<div class="container">
			<div class="flex-w">
				<span>
					<a href="index.html" class="s-txt21 hov-color-main trans-02">
						Inicio
					</a>
					<span class="s-txt21 p-l-6 p-r-9">/</span>
				</span>

				<span>
					<a href="about.html" class="s-txt21 hov-color-main trans-02">
						Prueba Ley 431
					</a>
					<span class="s-txt21 p-l-6 p-r-9">/</span>
				</span>
				
				<span>
					<span class="s-txt21">
						Cuestionario
					</span>
				</span>
			</div>
		</div>
	</div>
    );
}

export default Breadcrumb;
