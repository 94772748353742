import React, { Component } from 'react';
import GoogleMapReact, {  } from 'google-map-react';

const AnyReactComponent = ({ text }) => <div><span className="fa fa-map-marker fa-3x text-danger"></span></div>;

class Map extends Component {
    static defaultProps = {
        center: {
            lat: 12.1417199,
            lng: -86.2683064
        },
        zoom: 16
    };

    render() {
        return (
            // Important! Always set the container height explicitly
            <div class="map">
                <div className="contact-map h-size1">

                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyC2CWMrXHuPjKwyp-KfpAvWJbW29Kspp4c' }}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                    >
                        <AnyReactComponent
                            lat={12.14200}
                            lng={-86.26547}
                            text="My Marker"
                        />
                    </GoogleMapReact>
                </div>
            </div>
        );
    }
}

export default Map;
