import React from 'react';

const Features = () => {

	const courses = [
			{
				title: 'Seminario Teórico',
				description: 'Es un encuentro presencial, se imparte un sabado de 8 a 12:30, podes asistir cualquier sábado',
			},		
			{
				title: 'Horas Practicas',
				description: 'Recibiras 1 o 2 horas diarias de clases practicas de lunes a viernes hasta completar el total de horas practicas que incluya el curso',
			},
			{
				title: 'Material de estudio',
				description: 'Te facilitaremos una guía de preguntas ya contestadas y así te preparas para el exámen teórico en la dirección de tránsito nacional',
			},
			{
				title: 'Acompañamiento en trámite',
				description: 'Al finalizar tu curso te asesoramos en el tramite de tu licencia. Te realizamos las citas de tus pruebas y te facilitamos un vehiculo para tu prueba practica.',
			},
		]

    return (
        <div>
            <section class="bgwhite  p-t-75 p-b-90 features">
		<div class="container">
			<div>
				<h3 class="m-txt4 txt-center p-b-8 respon1">
					Acerca de los cursos
				</h3>

				<div class="bg-main size2 bo-rad-3 m-lr-auto"></div>
			</div>

			<div class="row">
				{
					courses.map((course, index) => {
						return <div class="flex-str col-sm-6 col-lg-3 p-t-65">
									<div class="block-2">
										<div class="wrap-b2 p-t-60 p-b-30 p-l-19 p-r-19">
											<div class="wrap-symbol-b2">	
												<div class="symbol-1 rotate-symbol-1">
													<i class="fa fa-film " aria-hidden="true"></i>
												</div>
											</div>
				
											<h4 class="m-txt5 txt-center p-b-8">
												{course.title}
											</h4>
											<p class="s-txt2 txt-center">
												{course.description}
											</p>
										</div>
									</div>
								</div>
					})
				}				
			</div>
		</div>
	</section>
        </div>
    );
}

export default Features;
