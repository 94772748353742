import React, { useState } from 'react';
import Dialog from 'react-dialog'
import InputMask from 'react-input-mask'
import 'react-dialog/css/index.css';

const DialogComponent = ({ callBack }) => {

    const [open, setOpen] = useState(true);
    const [identificacion, setIdentificacion] = useState('');
    const [hasError, setHasError] = useState({ has: false, message: '' });
    const [loading, setLoading] = useState(false);    
   
    const closeDialog = () => setOpen(false);

    const search = (e) => {
        setHasError({ has: false, message: '' });

        var iden = identificacion.trim();

        if(iden.length == 0){
            setHasError({ has: true, message: 'Ingrese el número de cédula' });
            return;
        }

        if(iden.length < 14){
            setHasError({ has: true, message: 'Debe contener de al menos 14 caracteres' });
            return;
        }

        setLoading(true);

        fetch('/resultado/search',  {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({identification : identificacion})
        }).then(resp => {

            const httpStatus = {
                ok : 200,
                badRequest : 400,
                notFound : 404,
                unauthorized : 401,
                internalServerError : 500
            }

            return new Promise((resolve, reject) => {

                if (resp.status == httpStatus.badRequest)
                    resp.text().then(err => reject(err));

                if (resp.status == httpStatus.notFound)
                    resp.text().then(err => reject(err));   
        
                if (resp.status == httpStatus.ok)
                    resp.json().then(data => resolve(data))
        
            })

        })
        .then(data => {
            const shorName = data.name?.split(' ')[0];

            callBack({ status : true, name : shorName });
            setOpen(false);

        }).catch(err => {
            setHasError({ has: true, message: err });
        })
        .finally(() => {
            setLoading(false);
        });
        
    }

    const cssClass = hasError.has ? 't-center mauto d-input d-input-error' : 't-center mauto d-input';

    return (
        <div className="container">
                {
                    open &&
                    <Dialog
                        width="300px"
                        height="280px"
                        position={{
                            x: -145,
                            y: -150
                        }}
                        title="Identifíquese"
                        modal={true}
                        onClose={closeDialog}
                        isDraggable={true}
                        hasCloseIcon={false}
                       >
                        <h3 className="t-center mar-bottom-15">Ingrese su número de cédula</h3>
                        {/* <input onChange={e => setIdentificacion(e.target.value)} value={identificacion} type="text" className={cssClass} placeholder="Ingrese su número de cédula" /> */}
                        <InputMask mask="999-999999-9999a" value={identificacion} className={cssClass} placeholder="000-000000-0000X" onChange={e => setIdentificacion(e.target.value)} />
                        <button onClick={search} className="btn btn-danger mar-top-10" type="button">
                            {loading ? 'Cargando...' : 'Ingresar'}
                        </button>
                        <span className="text-danger">{hasError.has && hasError.message}</span>
                    </Dialog>
                }
            </div>
    );
}

export default DialogComponent;
