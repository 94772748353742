import React from 'react';
import logo from '../img/Logo.JPG';

const Footer = () => {
    return (
        <div>
            <footer class="bg4 p-t-25">
		<div class="container p-b-60">
			<div class="row">
				<div class="col-sm-12 col-lg-4 p-t-30">
					<div class="w-full wrap-pic-max-w">
						<a href="index.html"><img src={logo} alt="LOGO"/></a>
					</div>

					<p class="s-txt9 p-t-30">
                    Escuela de Manejo Driver's les ofrese cursos en nivel principiante, avanzado , intermedio profesores , enfocados en el aprendizaje
					</p>
				</div>
				
				<div class=" col-sm-6 col-lg-4 p-t-30">
					<h4 class="m-txt12 p-t-14">
						OPCIONES
					</h4>

					<div class="wrap-link-footer p-t-28">
						<ul class="col-left">
							<li><a href="#">Inicio</a></li>
							<li><a href="#">Cursos</a></li>
							<li><a href="#">Ubicanos</a></li>
							<li><a href="#">Contacto</a></li>
							<li><a href="#">Exámen teórico</a></li>
						</ul>
					</div>
				</div>

				<div class=" col-sm-6 col-lg-4 p-t-30">
					<h4 class="m-txt12 p-t-14">
						Contactenos
					</h4>

					<ul class="contact-footer p-t-28">
						<li>
							<i class="fa fa-home" aria-hidden="true"></i>
							Hospital Bautista 2c abajo, 1/2 c al sur
						</li>

						<li>
							<i class="fa fa-phone" aria-hidden="true"></i>
							+505-2228-3678
						</li>
						<li>
							<i class="fa fa-home" aria-hidden="true"></i>
							Frente a los bomberos del Roberto Huembes
						</li>

						<li>
							<i class="fa fa-phone" aria-hidden="true"></i>
							+505-2227-7428
						</li>
						
						<li>
							<i class="fa fa-envelope-o" aria-hidden="true"></i>
                            <a href="mailto:emdrivers@hotmail.com">emdrivers@hotmail.com</a><br></br>
						</li>

						<li>
							<i class="fa fa-clock-o" aria-hidden="true"></i>
							Lun-Vie 09:30 am – 06:00 pm
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="bg3 txt-center p-t-19 p-b-16">
			<span class="s-txt10">@ {new Date().getFullYear()} Drivers</span>
			<span class="dis-inline s-txt11 hov-color-white trans-04"> Escuela de manejo</span>
		</div>
	</footer>
        </div>
    );
}

export default Footer;
