import React from 'react';

const Respuesta = ({ preguntaId, respuesta, onClick, index, selectedIndex }) => {

    const isSelected = selectedIndex === index;

    const isIMG = respuesta.indexOf('-') > -1 && respuesta.startsWith('3');

    return (
        <div className="mar-top-10">
            <div onClick={e => onClick(preguntaId, index)} className={`${isSelected ? 'pregunta-index-pointer pregunta-selected':'pregunta-index'}`}>
                {isIMG ? 
                    <img src={require(`../img/${respuesta}.PNG`)} alt="product" />
                    :
                    <p>{respuesta}</p>
                }	
            </div>
        </div>
    );

}

export default Respuesta;
