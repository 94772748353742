import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Footer from '../components/Footer';
import Map from '../components/Map';

const AboutPage = () => {
    return (
        <div>
            <Breadcrumb />
            <Map />
            <Footer />
        </div>
    );
}

export default AboutPage;
