import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from '../img/Logo.JPG';

export const NavMenu = props => {

	const cell1 = '+505-2228-3678';
	const dire1 = 'Hospital Bautista 2c abajo, 1/2 c al sur';

	const cell2 = '+505-2227-7428';
	const dire2 = 'Frente a los bomberos del Roberto Huembes';

	const hour = '09:30 am – 06:00 pm';

	const [collapsed, setCollapsed] = useState(true);

	const collapsedCss = collapsed ? 'menu-mobile' : 'menu-mobile d-block animate__animated animate__fadeInDown';

	const _button = collapsed ? 'btn-show-menu-mobile hamburger hamburger--squeeze' : 'btn-show-menu-mobile hamburger hamburger--squeeze is-active';

	return (
		<header>

			<div class="container-menu-desktop">
				<div class="top-bar bg-main">
					<div class="container">
						<div class="content-topbar">
							<div class="left-top-bar">

							</div>

							<div class="right-top-bar">
								<span>
									<i class="icon_phone" aria-hidden="true"></i>
									<span>{cell1}</span>
								</span>

								<span>
									<i class="icon_pin" aria-hidden="true"></i>
									<span>{dire1}</span>
								</span>

								<span>
									<i class="icon_phone" aria-hidden="true"></i>
									<span>{cell2}</span>
								</span>

								<span>
									<i class="icon_pin" aria-hidden="true"></i>
									<span>{dire2}</span>
								</span>

								<span>
									<i class="icon_clock" aria-hidden="true"></i>
									<span>{hour}</span>
								</span>
							</div>
						</div>
					</div>
				</div>

				<div class="wrap-menu-desktop">
					<div class="limiter-menu-desktop">


						<a href="index.html" class="logo">
							<img src={logo} alt="IMG-LOGO" />
						</a>


						<div class="menu-desktop">
							<ul class="main-menu">
								<li>									
									<Link to="/">Inicio</Link>
								</li>

								<li>
									<Link to="/cursos">Cursos</Link>
								</li>
								<li>
									<Link to="/ubicanos">Ubicanos</Link>
								</li>

								<li>
									<Link to="/contacto">Contacto</Link>
								</li>

								<li>
									<Link to="/preguntas">Examen Teórico</Link>
								</li>
							</ul>
						</div>

						<div class="social">
							<a href="https://www.facebook.com/escuelademanejodriver" target="_blank"><i class="fa fa-facebook m-l-13" aria-hidden="true"></i></a>
							<a href="https://wa.me/+50582873848" target="_blank"><i class="fa fa-whatsapp m-l-18" aria-hidden="true"></i></a>
						</div>

					</div>
				</div>
			</div>


			<div class="wrap-header-mobile">

				<a href="index.html" class="logo-mobile" style={{ display: 'flex' }}>
					<img src={logo} alt="IMG-LOGO" />
					<p className="m-txt4 txt-center p-b-8 respon1" style={{ marginLeft: '5px', marginTop: '15px' }}>
						Escuela de manejo
					</p>
				</a>



				<div class={_button} onClick={() => setCollapsed(!collapsed)}>
					<span class="hamburger-box">
						<span class="hamburger-inner"></span>
					</span>
				</div>

			</div>

			<div class={collapsedCss}>
				<ul class="topbar-mobile">

					<li class="right-top-bar bo1-b p-t-8 p-b-8">
						<span>
							<i class="icon_phone" aria-hidden="true"></i>
							<span>{cell1}</span>
						</span>
					</li>

					<li class="right-top-bar bo1-b p-t-8 p-b-8">
						<span>
							<i class="icon_pin" aria-hidden="true"></i>
							<span>{dire1}</span>
						</span>
					</li>

					<li class="right-top-bar bo1-b p-t-8 p-b-8">
						<span>
							<i class="icon_phone" aria-hidden="true"></i>
							<span>{cell2}</span>
						</span>
					</li>

					<li class="right-top-bar bo1-b p-t-8 p-b-8">
						<span>
							<i class="icon_pin" aria-hidden="true"></i>
							<span>{dire2}</span>
						</span>
					</li>

					<li class="right-top-bar bo1-b p-t-8 p-b-8">
						<span>
							<i class="icon_clock" aria-hidden="true"></i>
							<span>{hour}</span>
						</span>
					</li>
				</ul>

				<ul class="main-menu-m bg-main">
				
					<li class="bg-main">
						<Link to="/">Inicio</Link>
					</li>
					
					<li class="bg-main">
						<Link to="/cursos">Cursos</Link>
					</li>
					<li class="bg-main">
						<Link to="/ubicanos">Ubicanos</Link>
					</li>

					<li class="bg-main">
						<Link to="/contacto">Contacto</Link>
					</li>

					<li class="bg-main">
						<Link to="/preguntas">Exámen teórico</Link>
					</li>
					

				</ul>
			</div>
		</header>


	);

}
