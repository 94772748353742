import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import featured01 from '../img/featured-01.jpg';
import featured02 from '../img/featured-02.jpg';
import featured03 from '../img/featured03.jpg';

import slide1 from '../img/slide-01.jpg';
import slide2 from '../img/slide-02.jpg';
import slide3 from '../img/slide-03.jpg';


export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <>

        <div className="home">
          <section class="slider">
            <div class="rev_slider_wrapper fullwidthbanner-container rs1-revo">
              <div id="rev_slider_1" class="rev_slider fullwidthabanner" data-version="5.4.5">
                <ul>
                  <li data-transition="fade">
                    <img src={slide1} alt="IMG-SLIDE" class="rev-slidebg" />

                    <h2 class="tp-caption tp-resizeme txt1 txt-center p-l-15 p-r-15"
                      data-frames='[{"delay":500,"speed":1500,"frame":"0","from":"x:left;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                      data-x="center"
                      data-y="center"
                      data-width="['1200','992','768','480']"
                      data-hoffset="['0','0','0','0']"
                      data-voffset="['-85','-85','-85','-160']"
                      data-fontsize="['50', '50', '50', '50']"
                      data-lineheight="['60', '60', '60', '60']"
                      data-height="['auto']"
                    >Free video lessons</h2>

                    <p class="tp-caption tp-resizeme txt2 txt-center p-l-15 p-r-15"
                      data-frames='[{"delay":1500,"speed":1500,"frame":"0","from":"x:right;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                      data-x="center"
                      data-y="center"
                      data-width="['780','780','780','480']"
                      data-hoffset="['0','0','0','0']"
                      data-voffset="['-10','-10','-10','-50']"
                      data-fontsize="['18', '18', '18', '16']"
                      data-lineheight="['30', '30', '30', '30']"
                      data-height="['auto']"
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit.
                    </p>

                    <div class="tp-caption tp-resizeme wrap-btn-slide"
                      data-frames='[{"delay":3000,"speed":1500,"frame":"0","from":"y:bottom;rX:-20deg;rY:-20deg;rZ:0deg;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                      data-x="center"
                      data-y="center"
                      data-hoffset="['0','0','0','0']"
                      data-voffset="['75','75','75','90']"
                      data-height="['auto']">
                      <div class="p-l-10 p-r-10 p-t-5 p-b-5">
                        <a href="#" class="btn-drive size1 m-txt1 bg-main bo-rad-4 trans-03">
                          Our Services
                          <i class="fa fa-chevron-right m-l-9 fs-14" aria-hidden="true"></i>
                        </a>
                      </div>

                      <div class="p-l-10 p-r-10 p-t-5 p-b-5">
                        <a href="#" class="btn-drive size1 m-txt2 bgwhite bo-rad-4 trans-03">
                          Book Lesson
                          <i class="fa fa-chevron-right m-l-9 fs-14" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>

                  </li>

                  <li data-transition="fade">
                    <img src={slide2} alt="IMG-SLIDE" class="rev-slidebg" />

                    <h2 class="tp-caption tp-resizeme txt1 txt-center p-l-15 p-r-15"
                      data-frames='[{"delay":500,"speed":1500,"frame":"0","from":"y:top;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                      data-x="center"
                      data-y="center"
                      data-width="['1200','992','768','480']"
                      data-hoffset="['0','0','0','0']"
                      data-voffset="['-85','-85','-85','-160']"
                      data-fontsize="['50', '50', '50', '50']"
                      data-lineheight="['60', '60', '60', '60']"
                      data-height="['auto']"
                    >Free video lessons</h2>

                    <p class="tp-caption tp-resizeme txt2 txt-center p-l-15 p-r-15"
                      data-frames='[{"delay":1500,"speed":1500,"frame":"0","from":"y:bottom;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                      data-x="center"
                      data-y="center"
                      data-width="['780','780','780','480']"
                      data-hoffset="['0','0','0','0']"
                      data-voffset="['-10','-10','-10','-50']"
                      data-fontsize="['18', '18', '18', '16']"
                      data-lineheight="['30', '30', '30', '30']"
                      data-height="['auto']"
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit.
                    </p>

                    <div class="tp-caption tp-resizeme wrap-btn-slide"
                      data-frames='[{"delay":3000,"speed":1500,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                      data-x="center"
                      data-y="center"
                      data-hoffset="['0','0','0','0']"
                      data-voffset="['75','75','75','90']"
                      data-height="['auto']">
                      <div class="p-l-10 p-r-10 p-t-5 p-b-5">
                        <a href="#" class="btn-drive size1 m-txt1 bg-main bo-rad-4 trans-03">
                          Our Services
                          <i class="fa fa-chevron-right m-l-9 fs-14" aria-hidden="true"></i>
                        </a>
                      </div>

                      <div class="p-l-10 p-r-10 p-t-5 p-b-5">
                        <a href="#" class="btn-drive size1 m-txt2 bgwhite bo-rad-4 trans-03">
                          Book Lesson
                          <i class="fa fa-chevron-right m-l-9 fs-14" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </li>

                  <li data-transition="fade">
                    <img src={slide3} alt="IMG-SLIDE" class="rev-slidebg" />

                    <h2 class="tp-caption tp-resizeme txt1 txt-center p-l-15 p-r-15"
                      data-frames='[{"delay":500,"speed":1500,"frame":"0","from":"x:-500px;skX:85px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                      data-x="center"
                      data-y="center"
                      data-width="['1200','992','768','480']"
                      data-hoffset="['0','0','0','0']"
                      data-voffset="['-85','-85','-85','-160']"
                      data-fontsize="['50', '50', '50', '50']"
                      data-lineheight="['60', '60', '60', '60']"
                      data-height="['auto']"
                    >Free video lessons</h2>

                    <p class="tp-caption tp-resizeme txt2 txt-center p-l-15 p-r-15"
                      data-frames='[{"delay":1500,"speed":1500,"frame":"0","from":"x:500px;skX:-85px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                      data-x="center"
                      data-y="center"
                      data-width="['780','780','780','480']"
                      data-hoffset="['0','0','0','0']"
                      data-voffset="['-10','-10','-10','-50']"
                      data-fontsize="['18', '18', '18', '16']"
                      data-lineheight="['30', '30', '30', '30']"
                      data-height="['auto']"
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit.
                    </p>

                    <div class="tp-caption tp-resizeme wrap-btn-slide"
                      data-frames='[{"delay":3000,"speed":1500,"frame":"0","from":"y:bottom;rZ:90deg;sX:2;sY:2;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                      data-x="center"
                      data-y="center"
                      data-hoffset="['0','0','0','0']"
                      data-voffset="['75','75','75','90']"
                      data-height="['auto']">
                      <div class="p-l-10 p-r-10 p-t-5 p-b-5">
                        <a href="#" class="btn-drive size1 m-txt1 bg-main bo-rad-4 trans-03">
                          Our Services
                          <i class="fa fa-chevron-right m-l-9 fs-14" aria-hidden="true"></i>
                        </a>
                      </div>

                      <div class="p-l-10 p-r-10 p-t-5 p-b-5">
                        <a href="#" class="btn-drive size1 m-txt2 bgwhite bo-rad-4 trans-03">
                          Book Lesson
                          <i class="fa fa-chevron-right m-l-9 fs-14" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </li>


                </ul>
              </div>
            </div>
          </section>
          <section class="bgwhite p-t-70 p-b-40">
            <div class="container">
              <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-4 p-b-30 m-lr-auto">
                  <div class="block-1">
                    <div class="wrap-pic-b1 wrap-pic-w">
                      <img src={featured01} alt="IMG-FEATURE" />
                    </div>

                    <div class="wrap-text-b1 bg-main op-09 trans-05 ">
                      <a href="#" class="wrap-title-b1">
                        <i class="fa fa-car color-white fs-30" aria-hidden="true"></i>
                        <h4 class="m-txt3 color-white tt-up m-l-15">Curso de 15 horas prácticas</h4>
                      </a>

                      <div class="wrap-para-b1 p-l-50 p-r-20 m-tt-20 ">
                        <p class="s-txt1">
                          Nuestros cursos principiantes son completos, diseñados para que inicies desde cero, hasta que seas capaz de circular por rotondas, semaforos y demas vias principales
                        </p>
                      </div>

                      <div class="wrap-more-b1 p-l-50 p-t-10">
                        <a href="#" class="s-txt1 hov-color-white m-tt-10">
                          <i class="fa fa-caret-right fs-16 trans-04" aria-hidden="true"></i>
                          <span class=" trans-04">Leer mas</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-9 col-md-7 col-lg-4 p-b-30 m-lr-auto">
                  <div class="block-1">
                    <div class="wrap-pic-b1 wrap-pic-w">
                      <img src={featured02} alt="IMG-FEATURE" />
                    </div>

                    <div class="wrap-text-b1 bg-main op-09 trans-05">
                      <a href="#"><div class="wrap-title-b1">
                        <i class="fa fa-shield color-white fs-30" aria-hidden="true"></i>
                        <h4 class="m-txt3 color-white tt-up m-l-15">Vehiculos en excelente estado e instructores calificados</h4>
                      </div></a>

                      <div class="wrap-para-b1 p-l-37 p-r-20 m-tt-20">
                        <p class="s-txt1">
                          Contamos con una moderna flota vehicular e instrucctores que te inspiraran confianza y seguridad en tu aprendizaje.
                        </p>
                      </div>

                      <div class="wrap-more-b1 p-l-37 p-t-10">
                        <a href="#" class="s-txt1 hov-color-white m-tt-10">
                          <i class="fa fa-caret-right fs-16 trans-04" aria-hidden="true"></i>
                          <span class=" trans-04">Leer mas</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-9 col-md-7 col-lg-4 p-b-30 m-lr-auto">
                  <div class="block-1">
                    <div class="wrap-pic-b1 wrap-pic-w">
                      <img src={featured03} alt="IMG-FEATURE" />
                    </div>

                    <div class="wrap-text-b1 bg-main op-09 trans-05">
                      <a href="#"><div class="wrap-title-b1">
                        <i class="fa fa-id-card color-white fs-30" aria-hidden="true"></i>
                        <h4 class="m-txt3 color-white tt-up m-l-15">Acompañamiento en tramite de licencia</h4>
                      </div></a>

                      <div class="wrap-para-b1 p-l-50 p-r-20 m-tt-20">
                        <p class="s-txt1">
                          Al finalizar tu curso te asesoramos en el tramite de tu licencia. Te realizamos las citas de tus pruebas y te facilitamos un vehiculo para tu prueba practica.
                        </p>
                      </div>

                      <div class="wrap-more-b1 p-l-50 p-t-10">
                        <a href="#" class="s-txt1 hov-color-white m-tt-10">
                          <i class="fa fa-caret-right fs-16 trans-04" aria-hidden="true"></i>
                          <span class=" trans-04">Leer mas</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>
        </div>
       
      </>
    );
  }
}
