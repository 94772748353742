import React, { useState } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import DialogComponent from '../components/DialogComponent';

const SelectCategoryPage = props => {

    const [responseIndex, setResponseIndex] = useState(-1);
    const [response, setResponse] = useState({status: false, name: ''});

    const start = () => {
        props.history.push({pathname: '/preguntas-ley-431'},{ type: responseIndex});
    }

    return (
        <div>
            <Breadcrumb />
            <DialogComponent callBack={setResponse} />
            <div className="container-main mar-top-30">
                <div className="card">
                    <div className="card-header">
                        Examen de manejo                    
                    </div>
                    <div className="card-body">
                        <h3>{response.status && `Hola ${response.name}!` }</h3>
                        <p className="pregunta-text mar-bottom-30">Seleccione en que categoria quiere hacer las pruebas</p>
                        <div className="mar-top-10">
                            <div onClick={e => setResponseIndex(0)} className={`${responseIndex == 0 ? 'pregunta-index-pointer pregunta-selected':'pregunta-index'}`}>
                                <p className="wicon">                               
                                    Moto
                                    <div className="d-icon">
                                        <span className="fa fa-motorcycle"></span> 
                                    </div>
                                </p>
                            </div>
                        </div>
                        <div className="mar-top-10">
                            <div onClick={e => setResponseIndex(1)} className={`${responseIndex == 1 ? 'pregunta-index-pointer pregunta-selected':'pregunta-index'}`}>
                                <p className="wicon">
                                    Automovil
                                    <div className="d-icon">
                                        <span className="fa fa-car"></span> 
                                    </div>
                                </p>
                            </div>
                            
                        </div>
                        <div className="mar-top-10">
                            <div onClick={e => setResponseIndex(2)} className={`${responseIndex == 2 ? 'pregunta-index-pointer pregunta-selected':'pregunta-index'}`}>
                                <p className="wicon">
                                    Moto y Automovil
                                    <div className="d-icon">
                                        <span className="fa fa-motorcycle fa-mr-10"></span> 
                                        <span className="fa fa-car"></span> 
                                    </div>
                                </p>
                            </div>
                            
                        </div>
                        <div className="item-right">
                            {response.status && 
                                <button disabled={responseIndex==-1} className="btn btn-primary mar-top-10" onClick={() => start()}>Iniciar</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectCategoryPage;
