import React from 'react';
import Respuesta from './Respuesta';

const Pregunta = ({ request, index, total, pageIndex, nextPage, onClick }) => {

    const visible = index == pageIndex;

    const { selectedIndex } = request; 

    const containResponseSelected = (selectedIndex != null) && (selectedIndex != -1);

    const isLastPage = (total == (pageIndex + 1));

    return (
        <div className={`${visible ? '' : 'card-hidden'}`}>

            <div className="card">
                <div className="card-header">
                    Examen de manejo
                    <div className="item-right text-card-title-muted">
                        Pregunta {index + 1} de {total}
                    </div>
                </div>
                <div className="card-body">
                    <p className="pregunta-text mar-bottom-30">{request.text}</p>
                    {request.hasImage && <img src={require(`../img/${request.id}.PNG`)} alt="product" />}
                    {request && request.respuestas.map((respuesta, key) => <Respuesta 
                        key={key} 
                        index={key} 
                        onClick={onClick} 
                        respuesta={respuesta} 
                        selectedIndex={selectedIndex} 
                        preguntaId={request.id}
                    />)}
                    <div className="item-right">
                        <button disabled={!containResponseSelected} className="btn btn-primary mar-top-10" onClick={() => nextPage()}>{isLastPage ? 'Finalizar':'Siguiente'}</button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Pregunta;
