import React from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';

import './custom.css'
import Resultado from './components/Resultado';
import HomePage from './components/HomePage';
import CoursesPage from './page/CoursesPage';
import AboutPage from './page/AboutPage';
import SelectCategoryPage from './page/SelectCategoryPage';
import PreguntasLey431 from './page/PreguntasLey431';

const App = props => {

    return (
      <Layout {...props}>
        <Route exact path='/' component={HomePage} />
        <Route exact path='/cursos' component={CoursesPage} />
        <Route exact path='/ubicanos' component={AboutPage} />
        <Route exact path='/contacto' component={AboutPage} />
        <Route path='/preguntas' component={SelectCategoryPage} />
        <Route path='/preguntas-ley-431' component={PreguntasLey431} />
        <Route path='/resultado' component={Resultado} />
      </Layout>
    );
}

export default App 
