import React, { useEffect, useState } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Pregunta from '../components/Pregunta';

const PreguntasLey431 = props => {


    const [pageIndex, setPageIndex] = useState(0);

    const [preguntas, setPreguntas] = useState([]);

    const nextPage = () => {
        setPageIndex(pageIndex + 1);
    };

    const onClick = (preguntaId, respuestaId) => {

        for (let index = 0; index < preguntas.length; index++) {
            const pregunta = preguntas[index];

            if (pregunta.id == preguntaId) {
                pregunta.selectedIndex = respuestaId;
                break;
            }

        }

        setPreguntas([...preguntas]);


    }

    useEffect(() => {

        console.log(props.history);

        if (props.location?.state?.type == undefined)
            props.history.push('/preguntas');            
        else {

            const { type } = props.location.state;

            fetch('/questions?type=' + type)
                .then(response => response.json())
                .then(data => {
                    setPreguntas(data)
                });
        }
    }, [0]);

    const isComplete = pageIndex == preguntas.length;

    useEffect(() => {

        if (isComplete && preguntas.length > 0)
            props.history.push({ pathname: '/resultado' }, { preguntas });

    }, [isComplete]);

    return (<>
        <Breadcrumb />
        <div className="container-main">

            <div className="container-preguntas">
                {
                    preguntas &&
                    preguntas.map((pregunta, index) =>
                        <Pregunta
                            key={index}
                            index={index}
                            request={pregunta}
                            total={preguntas.length}
                            pageIndex={pageIndex}
                            nextPage={nextPage}
                            onClick={onClick}
                        />
                    )
                }
            </div>
        </div>
    </>
    );
}

export default PreguntasLey431;
