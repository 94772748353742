import React from 'react';

import { Home } from './Home';
import Features from './Features';
import Footer from './Footer';
import Map from './Map';
import Courses from './Courses';

const HomePage = () => {
    return (
        <div>
            <Home />
            <Courses />
            <Features />
            <Map />
            <Footer />
        </div>
    );
}

export default HomePage;
