import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Courses from '../components/Courses';
import Footer from '../components/Footer';

const CoursesPage = () => {
    return (
        <div>
            <Breadcrumb />
            <Courses />
            <Footer />
        </div>
    );
}

export default CoursesPage;
